var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-sheet',_vm._g({attrs:{"color":"transparent"}},on),[_c('btn-with-tooltip',{attrs:{"btnProps":{
          disabled: false,
          icon: true
        },"iconProps":{ icon: 'mdi-table-column' },"tooltipProps":{ bottom: true },"tooltipText":"Table Columns"}})],1)]}}])},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-list',{attrs:{"dense":"","flat":"","tile":""}},[_vm._l((Object.keys(_vm.calendarTableColumnTypes)),function(colGroup){return [_c('v-subheader',{key:_vm.calendarTableColumnTypes[colGroup].text,staticClass:"px-0 primary--text font-weight-bold text-subtitle-1"},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v(_vm._s(_vm.calendarTableColumnTypes[colGroup].icon))]),_vm._v(" "+_vm._s(_vm.calendarTableColumnTypes[colGroup].text))],1),_c('v-list-item-group',{key:'ig' + _vm.calendarTableColumnTypes[colGroup].text,attrs:{"dense":"","multiple":""},on:{"change":_vm.onChange},model:{value:(_vm.calendarTableColumns[colGroup]),callback:function ($$v) {_vm.$set(_vm.calendarTableColumns, colGroup, $$v)},expression:"calendarTableColumns[colGroup]"}},_vm._l((_vm.calendarTableColumnTypes[colGroup].subHeaders),function(column){return _c('v-list-item',{key:column.value,staticClass:"columnMenuListItem primary--text",attrs:{"value":column.value,"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var active = ref.active;
return [_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-checkbox',{attrs:{"dense":"","input-value":active}})],1),_c('v-list-item-content',{staticClass:"pl-2"},[_c('v-list-item-title',[_vm._v(_vm._s(column.text))])],1)]}}],null,true)})}),1)]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }