var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"grey lighten-4","flat":"","min-width":"300"}},[_c('v-toolbar',{attrs:{"dark":"","color":_vm.event.details.color,"height":"50"}},[_c('v-toolbar-title',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.event.details.title)}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('closeDetails')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',{staticClass:"py-2",attrs:{"dense":"","justify":"space-between"}},[_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('showDetails', { type: 'event', event: _vm.event })}}},on),[_c('v-icon',[_vm._v("mdi-calendar-search")])],1)]}}])},[_c('span',[_vm._v("Event Details")])]),_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('showDetails', { type: 'ci', event: _vm.event })}}},on),[_c('v-icon',[_vm._v("mdi-layers-search")])],1)]}}])},[_c('span',[_vm._v("Catalog item details")])]),_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('showDetails', { type: 'patron', event: _vm.event })}}},on),[_c('v-icon',[_vm._v("mdi-account-search")])],1)]}}])},[_c('span',[_vm._v("Patron Details")])])],1),_vm._l((_vm.event.fields),function(details,detail){return _c('v-row',{key:detail,attrs:{"dense":"","align":"center"}},[_c('v-col',{staticClass:"subtitle-1 primary--text font-weight-bold text-flex-nowrap",attrs:{"cols":"4"}},[_vm._v(_vm._s(detail))]),_c('v-col',{staticClass:"text-flex-nowrap text-left font-weight-bold"},[_vm._v(" "+_vm._s(details)+" ")])],1)})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"warning"},on:{"click":function($event){return _vm.$emit('editEvent', _vm.event.eventData)}}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-square-edit-outline")]),_vm._v("EDIT ")],1)]}}])},[_c('span',[_vm._v("Edit Event")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }