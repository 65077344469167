var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"fill-height":"","align-start":"","justify-start":"","dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{staticClass:"d-flex align-center justify-space-between primary--text",attrs:{"height":"40","flat":"","color":"background"}},[_c('v-sheet',{staticClass:"d-flex align-center justify-start flex-shrink-1 flex-grow-0",attrs:{"color":"transparent","width":"150"}},[_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"color":"primary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.eventViewTypes[_vm.eventView].icon)+" ")])],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.formatTooltipHtml(
                    'Event view',
                    _vm.eventViewTypes[_vm.eventView].text
                  )
                )}})])]}}])},[_c('v-list',_vm._l((Object.values(_vm.eventViewTypes)),function(viewItem){return _c('v-list-item',{key:viewItem.value,on:{"click":function($event){return _vm.setEventView(viewItem.value)}}},[_c('v-tooltip',{attrs:{"color":viewItem.disabled ? '' : 'primary',"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":viewItem.value === _vm.eventView ? 'primary' : 'disabled'}},on),[_vm._v(_vm._s(viewItem.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(viewItem.text))])])],1)}),1)],1),(_vm.eventView === 'list')?_c('btn-sort-menu',{on:{"change":_vm.onEventColumnChange}}):_vm._e(),(_vm.eventView === 'calendar')?_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-sheet',_vm._g({attrs:{"color":"transparent"}},on),[_c('btn-with-tooltip',{attrs:{"btnProps":{
                  icon: true
                },"iconProps":{ icon: 'mdi-arrow-split-horizontal' },"tooltipProps":{ bottom: true },"tooltipText":"Calendar height"}})],1)]}}],null,false,123120076)},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',[_c('v-slider',{attrs:{"color":_vm.color,"hide-details":"","max":"3500","min":_vm.calHeight || 800,"track-color":"grey","vertical":""},on:{"input":_vm.onHeightChange},model:{value:(_vm.calendarMonthHeight),callback:function ($$v) {_vm.calendarMonthHeight=$$v},expression:"calendarMonthHeight"}})],1)],1)],1):_vm._e(),(_vm.eventView === 'table')?_c('btn-event-table-column-menu',{attrs:{"selectedColumns":_vm.calendarTableColumns}}):_vm._e(),_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var menu = ref.on;
return [_c('v-tooltip',{attrs:{"color":"primary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.calendarViewTypes[_vm.calendarView].icon)+" ")])],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.formatTooltipHtml(
                    'Period view',
                    _vm.calendarViewTypes[_vm.calendarView].text
                  )
                )}})])]}}])},[_c('v-list',_vm._l((Object.values(_vm.calendarViewTypes)),function(viewItem){return _c('v-list-item',{key:viewItem.value,on:{"click":function($event){return _vm.setCalendarView(viewItem.value)}}},[_c('v-tooltip',{attrs:{"color":viewItem.disabled ? '' : 'primary',"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":viewItem.value === _vm.calendarView ? 'primary' : 'disabled'}},on),[_vm._v(_vm._s(viewItem.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(viewItem.text))])])],1)}),1)],1)],1),_c('v-sheet',{staticClass:"d-flex align-center justify-center flex-shrink-0 flex-grow-1",attrs:{"color":"transparent"}},[_c('v-btn',{attrs:{"color":"primary","icon":"","small":"","text":""},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-toolbar-title',[_c('v-menu',{ref:"datePickerMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"title primary--text font-weight-bold"},'v-sheet',attrs,false),on),[_vm._v(" "+_vm._s(_vm.title)+" ")])]}}]),model:{value:(_vm.datePickerShow),callback:function ($$v) {_vm.datePickerShow=$$v},expression:"datePickerShow"}},[_c('v-date-picker',{key:_vm.calendarView,attrs:{"no-title":"","scrollable":"","show-current":"","type":_vm.calendarView === 'month' ? 'month' : 'date',"value":_vm.calendarFocus},on:{"input":_vm.setFocus,"update:picker-date":_vm.setFocus}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.focusToday($event)}}},[_vm._v(" TODAY ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.$refs.datePickerMenu.save(_vm.calendarFocus)}}},[_vm._v(" CLOSE ")])],1)],1)],1),_c('v-btn',{attrs:{"color":"primary","icon":"","small":"","text":""},on:{"click":_vm.next}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-sheet',{staticClass:"d-flex align-center justify-end flex-shrink-1 flex-grow-0",attrs:{"color":"transparent","width":"150"}},[_c('btn-with-tooltip',{attrs:{"btnProps":{
            icon: true
          },"iconProps":{ icon: 'mdi-calendar-plus' },"tooltipProps":{ bottom: true },"tooltipText":"Add new event"},on:{"click":_vm.eventAdd}}),(!_vm.filterDrawer)?_c('filterBtn'):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"px-2",staticStyle:{"overflow-y":"scroll"},attrs:{"height":_vm.calHeight}},[_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded && _vm.eventView === 'calendar'),expression:"isLoaded && eventView === 'calendar'"}],attrs:{"height":_vm.calendarMonthHeight}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.orderBy(_vm.eventsList, 'name'),"event-color":_vm.eventColor,"event-end":"end_date","event-margin-bottom":2,"event-height":20,"event-overlap-mode":"column","event-start":"start_date","now":_vm.today,"type":_vm.calendarView},on:{"click:date":_vm.viewDay,"click:event":_vm.showEventMenu,"click:more":_vm.showEventsModal,"change":_vm.updateRange,"contextmenu:day":_vm.contextDay},scopedSlots:_vm._u([{key:"event",fn:function(ref){
          var event = ref.event;
return [_c('v-sheet',{staticClass:"d-flex align-center justify-start white--text  text-truncate px-1",attrs:{"color":_vm.eventColor(event),"height":"20"}},[_c('v-chip',{attrs:{"color":"secondary","x-small":"","outlined":""}},[_c('strong',[_vm._v(_vm._s(event.ciData.abbreviation))])]),_c('span',{staticClass:"text-truncate",domProps:{"innerHTML":_vm._s(_vm.eventLabel(event))}}),(event.notes)?_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-note-outline")]):_vm._e()],1)]}}]),model:{value:(_vm.calendarFocus),callback:function ($$v) {_vm.calendarFocus=$$v},expression:"calendarFocus"}})],1),(_vm.isLoaded && _vm.eventView === 'list')?_c('eventList',{attrs:{"events":_vm.eventsList,"dateRange":{ end: _vm.end, start: _vm.start }},on:{"showDetails":_vm.showDetails}}):_vm._e(),(_vm.isLoaded && _vm.eventView === 'table')?_c('eventTable',{attrs:{"events":_vm.eventsList,"height":_vm.calHeight,"columnsDisplayed":_vm.calendarTableColumns},on:{"showDetails":_vm.showDetails}}):_vm._e(),_c('v-dialog',{attrs:{"close-on-content-click":false,"close-on-click":!_vm.modalDetailsShow,"max-width":"350","hide-overlay":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('eventMenu',{attrs:{"event":_vm.selectedEvent},on:{"closeDetails":function($event){_vm.selectedOpen = false},"editEvent":_vm.eventEdit,"showDetails":_vm.showDetails}})],1),_c('v-dialog',_vm._b({model:{value:(_vm.modalDetailsShow),callback:function ($$v) {_vm.modalDetailsShow=$$v},expression:"modalDetailsShow"}},'v-dialog',_vm.modalDetailsProps,false),[_c(_vm.modalDetailsComp,_vm._b({key:_vm.modalDetailsShow + _vm.modalDetailsComp,tag:"component",attrs:{"event":_vm.selectedEvent},on:{"close":function($event){return _vm.onDetailsClose($event)},"editEvent":_vm.eventEdit,"eventModalAction":_vm.onDetailsAction,"eventUpdated":_vm.calendarCheckChanges}},'component',_vm.modalDetailsCompData,false))],1),_c('v-dialog',{key:("imgPrev" + (String(
            _vm.imagePreviewData && _vm.imagePreviewData.id
              ? _vm.imagePreviewData.id
              : 'none'
          ))),attrs:{"value":_vm.modalImageFullPreview,"transition":"dialog-transition"},on:{"input":function($event){return _vm.$store.dispatch('toggleModalImageFullPreview')}}},[_c('imagePreviewModal')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }